<template>
  <v-dialog
    v-model="showPasswordDialog"
    width="30%"
    transition="slide-x-transition"
    class="pa-5"
    @click:outside="cancel"
  >
    <v-card>
      <v-card-title class="justify-center">Enter your Password</v-card-title>
      <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(checkPassword)">
          <v-card-text class="pb-0">
            <v-row justify="center">
              <mex-p content="Enter your password" />
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <validation-provider v-slot="{ errors }" name="password" rules="required">
                  <v-text-field
                    prepend-icon="mdi-form-textbox-password"
                    label="Password"
                    type="password"
                    v-model="password"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-0" elevation="13">
            <v-row justify="center">
              <v-col cols="auto">
                <v-btn class="acceptBtn ma-2" type="submit">Check Password</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  class="rejectBtn ma-2"
                  @click="cancel"
                >Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import SystemUsersService from "../../services/systemUsers.service";

export default {
  name: "PasswordCheckDialog",
  components: {},
  props: {
    showPasswordDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: ""
    };
  },
  methods: {
    checkPassword() {
      SystemUsersService.checkUserPassword(this.password).then(() => {
        this.$toast.success("Password correct");
        this.password = "";
        this.$emit("accept");
      })
        .catch(() => {
          this.$toast.error("Authentication failed");
        });
    },
    cancel() {
      this.password = "";
      this.$emit("reject");
    }
  }
};
</script>

<style></style>
