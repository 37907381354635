/*
 * The navigator component only works in a secure environment (Https)
 * If used in an unsecure environment, do:
 * 1. Enter in URL: chrome:flags
 * 2. For "Insecure origins treated as secure" set origin IP
 * */
function copyToClipboard(content) {
  return new Promise(function(resolve, reject) {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        resolve(true);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export default copyToClipboard;
