<template>
  <v-dialog
    width="30%"
    v-model="showDialog"
    transition="slide-x-transition"
    class="pa-5"
    @click:outside="closeDialog"
  >
    <v-card>
      <v-card-title v-if="showUpdatePassword" class="justify-center">Update your password
      </v-card-title>
      <v-card-title v-if="show2Fak" class="justify-center">2 Factor Authentication</v-card-title>
      <v-card-title v-if="show2FakRecoveryCodes" class="justify-center">Your Recovery Codes
      </v-card-title>
      <v-template v-if="show2Fak || show2FakRecoveryCodes">
        <template v-if="show2Fak">
          <v-card-text>
            <mex-p
              class="pa-5"
              alignment="center"
              content="To ensure the best possible security, each login is subject to a two-factor authentication. To do this, you must scan this QR code with the Google Authenticator app after you have installed it on your smartphone."
            />
            <v-row justify="center">
              <mex-p
                alignment="center"
                content="Scan this with the Google Authenticator App"
              />
            </v-row>
            <v-row justify="center">
              <v-img :src="qrCode" max-height="300" max-width="300" />
            </v-row>
          </v-card-text>
        </template>
        <template v-else-if="show2FakRecoveryCodes">
          <div class="ma-4">
            <v-row v-for="code in recoveryCodes" :key="code" justify="center">
              <v-col cols="auto" class="pa-1">
                <mex-p class="mb-0" :content="code" />
              </v-col>
            </v-row>
          </div>
        </template>
        <v-card-actions class="ma-1" elevation="13">
          <v-row justify="center">
            <v-col cols="auto">
              <mex-btn
                v-if="show2FakRecoveryCodes"
                badgeContent="!"
                badgeValue="1"
                color="error"
                content="Copy"
                icon="mdi-clipboard-text-outline"
                @click="copyRecoveryCodes()"
              />
            </v-col>
            <v-col cols="auto">
              <mex-btn class="acceptBtn" @click="closeDialog" content="close" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-template>
      <v-template v-if="showUpdatePassword">
        <validation-observer v-slot="{ handleSubmit }">
          <v-form @submit.prevent="handleSubmit(validateUpdatePassword)">
            <v-card-text class="pb-0">
              <v-row justify="center">
                <mex-p content="Enter your old and new password" />
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" name="password" rules="required">
                    <v-text-field
                      prepend-icon="mdi-form-textbox-password"
                      label="Old Password"
                      type="password"
                      v-model="oldPassword"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="new password"
                    rules="required|password"
                  >
                    <v-text-field
                      prepend-icon="mdi-form-textbox-password"
                      label="New Password"
                      type="password"
                      v-model="newPassword"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="new password confirmation"
                    rules="required|passwordConfirm:@new password"
                  >
                    <v-text-field
                      prepend-icon="mdi-form-textbox-password"
                      label="Repeat new Password"
                      type="password"
                      v-model="newPasswordRepeated"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pa-0" elevation="13">
              <v-row justify="center">
                <v-col cols="auto">
                  <v-btn class="acceptBtn ma-2" type="submit">Update Password</v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    class="rejectBtn ma-2"
                    @click="closeDialog()"
                  >Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-template>
    </v-card>
  </v-dialog>
</template>

<script>
import SystemUsersService from "../../services/systemUsers.service";
import copyToClipboard from "../../functions/copyToClipboard";

export default {
  name: "SystemUserAuthenticationDialog",
  components: {},
  props: {
    showUpdatePassword: {
      type: Boolean,
      default: false
    },
    show2Fak: {
      type: Boolean,
      default: false
    },
    show2FakRecoveryCodes: {
      type: Boolean,
      default: false
    },
    showDialog: {
      type: Boolean,
      default: false,
      description: "Defines if dialog is shown or not"
    }, 
    qrCode: {
      type: String,
      default: ""
    },
    recoveryCodes: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordRepeated: "",
      showPWCheckQRCode: false,
      showPWCheckRecoveryCodes: false
    };
  },
  computed: {
    formatRecoveryCodes() {
      let shortcutContent = "";
      Object.keys(this.recoveryCodes).forEach((key) => {
        shortcutContent += `${this.recoveryCodes[key]}\n\n`;
      });
      return shortcutContent;
    }
  },
  methods: {
    validateUpdatePassword() {
      this.updatePassword();
    },
    updatePassword() {
      SystemUsersService.updatePasswordBySystemUserId(this.oldPassword, this.newPassword)
        .then(() => {
          this.$toast.success("Password updated");
          this.newPassword = "";
          this.oldPassword = "";
          this.newPasswordRepeated = "";
          this.showUpdatePassword = false;
          this.$emit("accept");
        })
        .catch(() => {
          this.$toast.error("Updating password failed. Please try again");
        });
    },
    copyRecoveryCodes() {
      copyToClipboard(this.formatRecoveryCodes);
    },
    closeDialog() {
      this.$emit("reject");
    }
  }
};
</script>

<style scoped>
/* Track */
::-webkit-scrollbar-track {
  background: var(--v-primary-base);
  border-radius: 10px;
  box-shadow: inset 0 0 5px var(--v-primary-darken2);
}
</style>
